import { sortBy } from "lodash";

import { EntityType, FuelCategory } from "constants/enums";


// Need to handle the `FuelCategory.All` case, when we should *not* send a fuel type
// to the API. The API interprets this to mean "give me all fuel types".
export function formatFuelCategories(fuels: FuelCategory[] | Set<FuelCategory>): Array<string> {
  const fuelArray = (fuels instanceof Set) ? Array.from(fuels) : fuels;

  if (fuelArray.includes(FuelCategory.All) || fuelArray.length === 0) {
    return undefined;
  }

  const disaggregated: string[] = fuelArray
    .filter(f => ![FuelCategory.All_Fossil, FuelCategory.All_Renewable].includes(f));

  if (fuelArray.includes(FuelCategory.All_Fossil)) {
    disaggregated.push('petroleum');
    disaggregated.push('natural_gas');
    disaggregated.push('coal');
  }

  if (fuelArray.includes(FuelCategory.All_Renewable)) {
    disaggregated.push('wind');
    disaggregated.push('solar');
    disaggregated.push('hydro');
  }

  return disaggregated.map(f => f.toString());
}


export function formatEntityCodes(entityType: EntityType, codes: string[]) {
  if (entityType === EntityType.BaSubregion) {
    if (codes.length === 0) {
      codes = ['1','2','3','4','5','6','7','8','9','10'];
    }
    return sortBy(codes.map((c) => `MISO.${c}`));
  } else {
    return sortBy(codes);
  }
}
