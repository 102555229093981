/* Idea from Stack Overflow https://stackoverflow.com/a/51683226  */
class MapboxGLButtonControl {
  constructor({
    className = "",
    title = "",
    // @ts-ignore
    eventHandler = evtHndlr,
    style = {}
  }) {
    // @ts-ignore
    this._className = className;
    // @ts-ignore
    this._title = title;
    // @ts-ignore
    this._eventHandler = eventHandler;
    // @ts-ignore
    this._style = style;
  }
  // @ts-ignore
  onAdd(map) {
    // @ts-ignore
    this._btn = document.createElement("button");
    // @ts-ignore
    this._btn.className = "mapboxgl-ctrl-icon" + " " + this._className;
    // @ts-ignore
    this._btn.type = "button";
    // @ts-ignore
    this._btn.title = this._title;
    // @ts-ignore
    this._btn.onclick = this._eventHandler;
    // @ts-ignore
    this._btn.style = this._style;
    // @ts-ignore
    this._container = document.createElement("div");
    // @ts-ignore
    this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
    // @ts-ignore
    this._container.appendChild(this._btn);
    // @ts-ignore
    return this._container;
  }

  onRemove() {
    // @ts-ignore
    this._container.parentNode.removeChild(this._container);
    // @ts-ignore
    this._map = undefined;
  }
}

export default MapboxGLButtonControl;
