import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';


// Helper component for including a '?' icon with some helpful text.
export const HelpPopup = (props: { popupContent: any }) => {
  return (
    <Tooltip title={props.popupContent} sx={{fontSize: 14}} arrow>
      <IconButton style={{padding: 0, marginLeft: '5px'}}>
        <QuestionMarkIcon fontSize='small' style={{padding: 0, fontSize: '12px'}}/>
      </IconButton>
    </Tooltip>
  );
}
