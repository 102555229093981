import { RootState } from 'modules/store';

const root = (state: RootState) => state.app;

export const currentAppState = (state: any) => root(state);
export const currentMapHistory = (state: any) => root(state).mapHistory;
export const currentMapHistoryLength = (state: any) => root(state).mapHistory.length;


const getCurrentUser = (state: RootState) => root(state).currentUser;
const getCurrentCustomer = (state: RootState) => root(state).currentCustomer;
const getAllAttributes = (state: RootState) => (getCurrentUser(state)?.attributes || []).concat(getCurrentCustomer(state)?.attributes || []);
const getGates = (state: RootState) => getAllAttributes(state).filter(attr => attr.namespace === 'GATES');