import * as React from 'react';

import { FuelCategory, MonthOrSeason, Pollutant } from 'constants/enums';
import { formatPollutant } from 'utils/utils';


export interface ISelectOption {
  value: string;
  label: string | JSX.Element;
}


export interface IPollutantOption extends ISelectOption {
  value: Pollutant
}

export const regionSourceOptions: {value: 'EIA' | 'ISO', label: string}[] = [
  {value: 'EIA', label: 'EIA'},
  {value: 'ISO', label: 'ISO'},
]

export const pollutantOptions: IPollutantOption[] = [
  { value: Pollutant.CO2, label: formatPollutant(Pollutant.CO2) },
  { value: Pollutant.CO2e, label: formatPollutant(Pollutant.CO2e) },
  { value: Pollutant.NOx, label: formatPollutant(Pollutant.NOx) },
  { value: Pollutant.SO2, label: formatPollutant(Pollutant.SO2) }
];

export const fuelTypeOptions = [
  { value: FuelCategory.All_Fossil, label: <div className="fuel-type-options--category">Fossil Fuels <div className="fuel-type-options--count">3</div></div> },
  { value: FuelCategory.Coal, label: 'Coal' },
  { value: FuelCategory.Oil, label: 'Oil/Petroleum' },
  { value: FuelCategory.Natural_Gas, label: 'Natural Gas' },

  { value: FuelCategory.All_Renewable, label: <div className="fuel-type-options--category">Renewables <div className="fuel-type-options--count">3</div></div> },
  { value: FuelCategory.Wind, label: 'Wind' },
  { value: FuelCategory.Solar, label: 'Solar' },
  { value: FuelCategory.Hydro, label: 'Hydro' },

  { value: FuelCategory.All_Other, label: <div className="fuel-type-options--category">Other <div className="fuel-type-options--count">2</div></div> },
  { value: FuelCategory.Nuclear, label: 'Nuclear' },
  { value: FuelCategory.Other, label: 'Other' },
];


export const expandedFuelTypeOptions = [
  { value: FuelCategory.Geothermal, label: 'Geothermal' },
  { value: FuelCategory.Storage, label: 'Storage' },
  { value: FuelCategory.Biomass, label: 'Biomass' },
  { value: FuelCategory.Wood, label: 'Wood' },
  { value: FuelCategory.Waste, label: 'Waste' },
]

export const monthOrSeasonOptions = [
  { value: MonthOrSeason.All, label: 'All' },
  {
    label: "Months",
    options: [
      { value: MonthOrSeason.Jan, label: 'Jan' },
      { value: MonthOrSeason.Feb, label: 'Feb' },
      { value: MonthOrSeason.Mar, label: 'Mar' },
      { value: MonthOrSeason.Apr, label: 'Apr' },
      { value: MonthOrSeason.May, label: 'May' },
      { value: MonthOrSeason.Jun, label: 'Jun' },
      { value: MonthOrSeason.Jul, label: 'Jul' },
      { value: MonthOrSeason.Aug, label: 'Aug' },
      { value: MonthOrSeason.Sep, label: 'Sep' },
      { value: MonthOrSeason.Oct, label: 'Oct' },
      { value: MonthOrSeason.Nov, label: 'Nov' },
      { value: MonthOrSeason.Dec, label: 'Dec' },
    ]
  },
  {
    label: "Subyears",
    options: [
      { value: MonthOrSeason.SY1, label: 'Subyear 1 (Dec-Feb)' },
      { value: MonthOrSeason.SY2, label: 'Subyear 2 (Mar-May)' },
      { value: MonthOrSeason.SY3, label: 'Subyear 3 (Jun-Aug)' },
      { value: MonthOrSeason.SY4, label: 'Subyear 4 (Sep-Nov)' },
    ]
  }
];

