import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Select from 'react-select';

import dayjs from 'dayjs';

import Highcharts, { Chart, DashStyleValue, setOptions as HighchartSetOptions, SeriesOptionsType, chart as chartFactory } from 'highcharts';

import * as Constants from 'constants/constants';
import { ChartViewMode, Duration, MapLevel, MonthOrSeason, Units } from 'constants/enums';
import { CompactEmissionEvent } from 'constants/interfaces';
import { fuelCategoryPalette } from 'utils/ColorPalette';
import { singularitySelectStyle } from 'utils/ReactSelectStyle';
import { arrayMax, arraySum, findLowestCommonOom, formatFuelCategoryName, formatNumber, last, monthOrSeasonToString } from 'utils/utils';

import { currentAppState, currentMapHistory } from 'modules/app/selectors';
import { changeMapLevel, updateAppState } from 'modules/app/slice';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { prop, sortBy } from 'rambda';
import { TrackEventNames, tracker } from 'utils/tracker';
import { getTickPositions } from './utils';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
var chart: Chart = null;


HighchartSetOptions({
  lang: {
    thousandsSep: ','
  },
});


interface IResolutionOption {
  value: Duration
  label: string
}


interface ITimeSeriesChartProps {
  seriesByFuelCategory: {[fuelCategory: string] : CompactEmissionEvent[]}
  loading: boolean
  waiting: boolean
}


// Highchart receives timestamps in a 'millis since epoch' format.
function toUnixMillis(t: string): Number {
  const ts = dayjs(t);
  if (!ts.isValid()) {
    console.error(`Invalid time string '${t}' in toUnixMillis()`);
  }
  return ts.valueOf();
}


const TimeSeriesChart = (props: ITimeSeriesChartProps) => {
  const dispatch = useAppDispatch();
  const appState = useAppSelector(currentAppState);
  const mapHistory = useAppSelector(currentMapHistory);
  const mapState = last(mapHistory);

  const [displayByGrouping, setDisplayByGrouping] = useState<'fuel' | 'region' | 'none'>('fuel');
  const [viewMode, setViewMode] = useState(ChartViewMode.Emissions);
  const isImperialUnits = appState.units === Units.Imperial;
  const conversionDivisor = isImperialUnits ? 1 : 2.2;
  const toTonsDivisor = isImperialUnits ? 2000 : 1000;
  const isProjectionsPage = window.location.pathname.endsWith('/projections');

  if (props.loading && chart) {
    chart.showLoading();
  }

  const createChart = (plotData: any, totalSeries: any) => {
    if (!plotData) {
      return;
    }

    const yAxisUnits = {
      [ChartViewMode.Emissions]: `tons of ${appState.pollutant}`,
      [ChartViewMode.Generation]: 'MWh',
      [ChartViewMode.Intensity]: `${isImperialUnits ? 'lbs' : 'kg'} ${appState.pollutant} / MWh`
    }[viewMode];

    // Find the lowest common OOM among values so that we can label consistently.
    let numbers: number[] = [];
    plotData.forEach((el: any) => {
      const candidate = (Math.max(
        ...el.data.map((xy: any) => xy.y)));
      if (candidate > 0) {
        numbers.push(candidate);
      }
    });

    // NOTE(milo): I'm allowing 1/3 of values to have an order of magnitude that
    // is too high to prioritize showing bigger fuel types.
    const lowestCommonOom = findLowestCommonOom(numbers, Math.floor(numbers.length / 3));

    const tooltipDateFmt = {
      [Duration.Hour]: 'ha, MMM DD, YYYY',
      [Duration.Day]: 'MMM DD, YYYY',
      [Duration.Month]: 'MMM, YYYY',
      [Duration.Subyear]: 'MMM, YYYY',
      [Duration.Year]: (appState.monthOrSeason === MonthOrSeason.All) ?
          'YYYY' : `[${monthOrSeasonToString(appState.monthOrSeason)}] YYYY`
    }[appState.timeResolution];

    let plotLines = undefined;

    const rraStartYear = dayjs(`${Constants.LATEST_HOURLY_OR_DAILY_DATA_YEAR}-12-01T00:00:00+00:00`);
    if (appState.queryEndDate >= rraStartYear) {
      plotLines = [{
        color: `rgba(0, 0, 0, 0.3)`,
        dashStyle: 'ShortDash' as DashStyleValue,
        width: 2,
        zIndex: 1,
        value: rraStartYear.valueOf(),
        label: {
          text: 'Forecasted Data',
          x: 4,
          style: {color: 'rgba(0, 0, 0, 0.6)'},
          y: 24,
          rotation: 0,
        }
      }];
    }

    const tickPositionsAndFormat = getTickPositions(appState, plotData);
    const shouldDisplayInUTC = appState.timeResolution !== Duration.Hour;
    const localTimezoneStr = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (tickPositionsAndFormat.positions.length === 1) {
      plotData.forEach((data: {type: string}) => data.type = 'column');
    }

    chart = chartFactory('series-chart-container', {
      chart: {
        style: {
          fontFamily: Constants.FONT_FAMILY,
          fontSize: '10px',
          color: 'black',
        },
        zoomType: 'x',
      },

      time: {
        useUTC: shouldDisplayInUTC
      },

      series: plotData,

      exporting: {
        enabled: true,
        scale: 4,
        buttons: {
          contextButton: {
            symbol: "menuball",
            menuItems: ["viewFullscreen", "separator", "downloadCSV", "downloadPNG", "downloadJPEG", "downloadSVG"],
          },
        },
        menuItemDefinitions: {
          downloadCSV: {
            onclick: function () {
              document.getElementById(Constants.DOWNLOAD_BUTTON_ID).click();
            },
            text: 'Download CSV'
          }
        },
        chartOptions: {
          navigator: {
            enabled: false
          },
          scrollbar: {
            enabled: false
          },
          rangeSelector: {
            enabled: false
          },
          chart: {
            style: {
              fontFamily: Constants.FONT_FAMILY,
            },
          }
        },
        fallbackToExportServer: false
      },

      rangeSelector: {
        enabled: false,
        buttons: [
          { type: 'week', count: 1, text: '1W' },
          { type: 'month', count: 1, text: '1M' },
          { type: 'month', count: 3, text: '3M' },
          { type: 'month', count: 6, text: '6M' },
          { type: 'year', count: 1, text: '1Y' },
          { type: 'all', text: 'All' }
        ]
      },
      title: {
        text: null
      },
      subtitle: {
        text: null
      },
      xAxis: {
        labels: {
          step: 1,
          rotation: 90,
          allowOverlap: false,
          format: tickPositionsAndFormat.format
        },
        // For some reason, this crashes when tickPositionsArray has only one
        // element. Add a check here to avoid.
        tickPositions: tickPositionsAndFormat.positions.length > 1 ? tickPositionsAndFormat.positions : null,
        startOnTick: true,
        plotLines: plotLines
      },
      yAxis: {
        labels: {
          formatter: function() {
            // TODO(milo): parseFloat?
            // @ts-ignore
            return formatNumber(this.value, 2);
          }
        },
        title: {
          text: yAxisUnits
        },
        plotLines: [{
          value: 0,
          width: 1,
          color: 'gray'
        }],
        reversedStacks: false,
        min: 0, // Don't show negative generation or emissions!
      },

      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'top',
        reversed: false,
        enabled: displayByGrouping === 'none' && viewMode !== ChartViewMode.Intensity,
        itemStyle: {
          fontSize: '12px',
          fontFamily: Constants.FONT_FAMILY
        },
      },

      // The scrollbar is ugly so disable.
      scrollbar: {
        enabled: false,
        liveRedraw: true
      },

      plotOptions: {
        area: {
          step: 'center'
        },
        column: {
          minPointLength: 3,
        },
        series: {
          stacking: displayByGrouping !== 'region' ? 'normal' : undefined,
          dataGrouping: {
            enabled: false
          },
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              }
            }
          },
        },
      },

      navigator: {
        enabled: false,
      },

      tooltip: {
        formatter: function() {
          if (!this.points) {
            return;
          }
          let tooltipText = `<b>${dayjs(this.x).format(tooltipDateFmt)} (${localTimezoneStr})</b></br>`;
          if (shouldDisplayInUTC) {
            tooltipText = `<b>${dayjs.utc(this.x).format(tooltipDateFmt)} (UTC)</b></br>`;
          }
          let totalY = 0;
          this.points.reverse().forEach((point) => {
            // Don't show zero-valued labels (e.g emissions for renewables).
            if (point.y <= 0) {
              return;
            }
            const txt = `
              <p style="color:${point.color}">${point.series.name}:
              <strong>${formatNumber(point.y, 2, lowestCommonOom)} ${yAxisUnits}</strong></p></br>`;
            tooltipText += txt;
            totalY += point.y;
          });
          if (this.points.length > 1) {
            tooltipText += `<strong>Total: ${formatNumber(totalY, 2, lowestCommonOom)}</strong>`;
          }
          return tooltipText;
        },
        // Determines whether you hover on one series at a time or all of them.
        split: false,
        shared: true,
      },

      credits: {
        enabled: false,
      },
    });
  }

  // Need to create the chart here so that the DOM renders first.
  const processData = () => {
    let plotData: SeriesOptionsType[] = [];

    // Put the input data into a format that's ready for plotting below.
    if (viewMode === ChartViewMode.Emissions || viewMode === ChartViewMode.Generation) {
      // @ts-expect-error: highcharts types has issues
      plotData = Object.entries(props.seriesByFuelCategory).map(([fuelType, data]) => {
        return {
          type: 'area',
          name: formatFuelCategoryName(fuelType),
          color: fuelCategoryPalette[fuelType],
          data: data.map((e: CompactEmissionEvent, i: number) => {
            const netElectricityMwhPositive = e.netElectricityMwh >= 0 ? e.netElectricityMwh : -e.netElectricityMwh;
            let value = netElectricityMwhPositive;
            if (viewMode === ChartViewMode.Emissions) {
              value = {
                'CO2': (e.co2MassLb / conversionDivisor) / toTonsDivisor,
                'SO2': (e.so2MassLb / conversionDivisor) / toTonsDivisor,
                'NOx': (e.noxMassLb / conversionDivisor) / toTonsDivisor,
                'CO2e': (e.co2EMassLb / conversionDivisor) / toTonsDivisor,
                'None': 0
              }[appState.pollutant];
            }
            return {
              x: toUnixMillis(e.startDate),
              y: value,
              color: fuelCategoryPalette[fuelType]
            };
          })
        }
      })
      .sort((a, b) => {
        const totalValueA = arraySum(a.data.map((el) => el.y));
        const totalValueB = arraySum(b.data.map((el) => el.y));
        return totalValueB - totalValueA;
      })
      .filter((a: any) => arrayMax(a.data.map((el: any) => el.y)) >= 0);
    }

    let totalMwhByStartDate: {[dt: string] : number} = {};
    let totalLbsByStartDate: {[dt: string] : number} = {};
    Object.entries(props.seriesByFuelCategory).forEach(([fuelType, data]) => {
      data.forEach((e: CompactEmissionEvent) => {
        // const startDate = toUnixMillis(e.startDate);
        const netElectricityMwhPositive = e.netElectricityMwh >= 0 ? e.netElectricityMwh : -e.netElectricityMwh;
        if (!totalMwhByStartDate.hasOwnProperty(e.startDate)) {
          totalMwhByStartDate[e.startDate] = 0;
        }
        if (!totalLbsByStartDate.hasOwnProperty(e.startDate)) {
          totalLbsByStartDate[e.startDate] = 0;
        }
        totalMwhByStartDate[e.startDate] += netElectricityMwhPositive;
        totalLbsByStartDate[e.startDate] += {
          'CO2': e.co2MassLb / conversionDivisor,
          'SO2': e.so2MassLb / conversionDivisor,
          'NOx': e.noxMassLb / conversionDivisor,
          'CO2e': e.co2EMassLb / conversionDivisor,
          'None': 0
        }[appState.pollutant];
      });
    });

    let totalSeries = [{
      name: 'Total',
      step: 'left',
      type: 'area',
      // @ts-expect-error: highcharts types has issues
      data: []
    }];

    // For intensity data, we need to aggregate across ALL fuel types.
    if (viewMode === ChartViewMode.Intensity) {
      totalSeries[0].data = Object.entries(totalLbsByStartDate).map(([startDate, lbs]) => {
        return {
          x: toUnixMillis(startDate),
          y: (totalMwhByStartDate[startDate] > 0) ? lbs / totalMwhByStartDate[startDate] : 0,
        };
      });
    } else if (viewMode === ChartViewMode.Emissions) {
      totalSeries[0].data = Object.entries(totalLbsByStartDate).map(([startDate, lbs]) => {
        return {
          x: toUnixMillis(startDate),
          y: lbs / 2000,
        };
      });
    } else {
      totalSeries[0].data = Object.entries(totalMwhByStartDate).map(([startDate, mwh]) => {
        return {
          x: toUnixMillis(startDate),
          y: mwh,
        };
      });
    }

    totalSeries[0].data.sort((a, b) => (b[0] - a[0]));

    if (displayByGrouping === 'none' || viewMode === ChartViewMode.Intensity) {
      // @ts-expect-error: highcharts types has issues
      plotData = totalSeries;
    }

    createChart(plotData, totalSeries);
  }

  // states can't show hourly or daily data
  const timeResolutionOptions = [
    {value: Duration.Subyear, label: 'Subyear', order: 3},
    {value: Duration.Year, label: 'Yearly', order: 4},
  ];

  if (mapState.mapLevel !== MapLevel.States && !isProjectionsPage) {
    timeResolutionOptions.push({value: Duration.Hour, label: 'Hourly', order: 0});
    timeResolutionOptions.push({value: Duration.Day, label: 'Daily', order: 1});
  }

  if (!isProjectionsPage) {
    timeResolutionOptions.push({value: Duration.Month, label: 'Monthly', order: 2});
  }

  useEffect(() => {
    tracker.track(TrackEventNames.VIEWED_CHART, {displayByGrouping, chartType: 'area', viewMode});
  }, [viewMode, displayByGrouping]);

  // This is where the chart gets updated.
  useEffect(() => {
    processData();
  }, [props.seriesByFuelCategory, viewMode, props.waiting, props.loading, displayByGrouping, appState]);

  const groupingOptions = [
    viewMode === ChartViewMode.Intensity ? undefined : {label: 'Group by fuel', value: 'fuel'},
    // TODO: enable this when the backend supports grouping by region
    // {label: 'Group by region', value: 'region'},
    {label: 'Do not group data', value: 'none'}
  ].filter(x => !!x);

  return (
    <>
      <Stack
        direction="row"
        alignContent={'center'}
        alignItems={'center'}
        p={1}
        className="time-series-toolbar"
      >
        <Tooltip title="Change the chart view">
          <ToggleButtonGroup
            className='tour--emissions-view'
            color="primary"
            sx={{mr: 2}}
            value={viewMode}
            exclusive
            onChange={(event, value) => {
              value && setViewMode(value);
            }}
          >
            <ToggleButton value={ChartViewMode.Emissions}>Emissions</ToggleButton>
            <ToggleButton value={ChartViewMode.Intensity}>Intensity</ToggleButton>
            <ToggleButton value={ChartViewMode.Generation}>Electricity</ToggleButton>
          </ToggleButtonGroup>
        </Tooltip>
        <Select
          className='react-select-toolbar-smallfont tour--time-resolution-dropdown time-resolution-dropdown'
          options={sortBy(prop('order'), timeResolutionOptions)}
          onChange={(value: IResolutionOption, _) => {
            dispatch(updateAppState({ timeResolution: value.value }));
            if ([Duration.Hour, Duration.Day].includes(value.value)) {
              dispatch(changeMapLevel({level: MapLevel.ISOs, selectedRegion: 'MISO'}));
            }
          }}
          value={timeResolutionOptions.find(el => (el.value === appState.timeResolution))}
          styles={singularitySelectStyle}
        />
        <Select
          className='react-select-toolbar-smallfont'
          options={groupingOptions}
          onChange={({value}) => setDisplayByGrouping(value as 'fuel' | 'none' | 'region')}
          value={viewMode === ChartViewMode.Intensity ? last(groupingOptions) : groupingOptions.find(({value}) => value === displayByGrouping)}
          isDisabled={ChartViewMode.Intensity === viewMode}
          styles={singularitySelectStyle}
        />
      </Stack>
      <Box>
        <div id="series-chart-container"></div>
      </Box>
    </>
  );
}

export default TimeSeriesChart;
