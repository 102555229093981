import React, { useEffect, useState } from 'react';

import * as Constants from 'constants/constants';
import { FuelCategory } from 'constants/enums';

import { currentAppState, currentMapHistory } from 'modules/app/selectors';
import { editMapSelection, updateAppState } from 'modules/app/slice';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { currentTourState } from 'modules/tour/selectors';
import { setUserShouldDoTour } from 'modules/tour/slice';
import Joyride, { ACTIONS, CallBackProps, EVENTS, LIFECYCLE, STATUS, Step } from 'react-joyride';
import { last } from 'utils/utils';

const tourCompletionCookieName = 'Singularity.HistoricalEmissionsMap.tourVersion';

const steps: Step[] = [
  // STEP 0
  {
    target: 'body',
    placement: 'center',
    content: (
      <div style={{textAlign: 'left'}}>
        <h1 style={{fontSize: '24px'}}><strong>MISO Grid Emissions Map</strong></h1>
        <strong>This dashboard visualizes emissions and generation from the MISO grid.</strong>
        <hr/>
        <strong style={{textDecoration: 'underline'}}>Data Availability:</strong>
        <ul>
          <li>Annual/monthly data for 2005-2022</li>
          <li>Daily/hourly data for 2019-2022</li>
          <li>Subregional data across 10 Local Resource Zones (LRZs)</li>
          <li>Emissions for CO<sub>2</sub>, CO<sub>2</sub>eq, NO<sub>x</sub>, and SO<sub>2</sub></li>
        </ul>
        <hr/>
        <p>For more information, take a look at Singularity's <a href={Constants.DASHBOARD_DOCS_URL} target='_blank'>Open Grid Emissions documentation</a>.</p>
        <p>Click 'Next' for a quick tour of dashboard features, data selections, and information sources.</p>
      </div>
    ),
  },
  // STEP 1
  {
    target: '.tour--select-map-region',
    content: 'Try clicking an LRZ on the map or choosing one from this dropdown.',
    placement: 'left',
    styles: {spotlightLegacy: {paddingBottom: 306}, overlayLegacy: {pointerEvents: 'none'}}
  },
  // STEP 2
  {
    target: '.tour--zoom-to-selection',
    content: 'Click this to zoom into the LRZ you selected.',
    placement: 'right',
  },
  // STEP 3
  {
    target: '.tour--map-panel',
    content: 'Each dot on the map is a power plant. You can click on them to see more information.',
    placement: 'center',
  },
  // STEP 4
  {
    target: '.tour--time-series-chart',
    content: 'This sidebar visualizes emission and generation data from your selected LRZ(s). This is a plot of emissions over time, broken down by fuel type.',
  },
  // STEP 5
  {
    target: '.tour--time-resolution-dropdown',
    content: 'Here, you can choose what granularity to view the data at.',
    placement: 'right',
    styles: {spotlightLegacy: {paddingBottom: 174}, overlayLegacy: {pointerEvents: 'none'}},
  },
  // STEP 6
  {
    target: '.tour--emissions-view',
    content: 'You can view a time series of emissions, emissions intensity, or generation.',
  },
  // STEP 7
  {
    target: '.tour--fuel-types',
    content: 'Try filtering the data to show \'Natural Gas\'.',
    placement: 'right',
    styles: {spotlightLegacy: {paddingBottom: 306}, overlayLegacy: {pointerEvents: 'none'}}
  },
  // STEP 8
  {
    target: '.tour--settings-menu',
    content: 'You can access some more advanced settings in this menu. For example, you can choose whether to view the \'Simple\' or \'Expanded\' (more detailed) fuel categories.',
    placement: 'right'
  },
  // STEP 9
  {
    target: '.tour--data-download',
    content: 'You can export the data that\'s currently displayed by clicking here.'
  },
  // STEP 10
  {
    target: '.tour--exit-region',
    content: 'Click this button to exit the currently selected region(s).'
  },
  // STEP 11
  {
    target: '.tour--new-navbar',
    content: 'You can view data for near real-time and future projections by switching pages here.'
  },
  // STEP 12
  {
    target: '.tour--start-over',
    content: (
      <div>
        <h5><strong>Done!</strong></h5>
        <br />
        <p>You can click here to replay the walkthrough at any time.</p>
        <p>For more information, check out the <a href='https://help.misoenergy.org/knowledgebase/article/KA-01501/en-us' target='_blank'>MISO Help Center</a> and Singularity's <a href={Constants.DASHBOARD_DOCS_URL} target='_blank'>Open Grid Emissions documentation</a>.</p>
      </div>
    ),
  },
];


const JoyrideTour = () => {
  const dispatch = useAppDispatch();

  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [resetTour, setResetTour] = useState(0);

  const tourState = useAppSelector(currentTourState);
  const appState = useAppSelector(currentAppState);
  const mapHistory = useAppSelector(currentMapHistory);
  const mapState = last(mapHistory);

  useEffect(() => {
    // Check if the user already skipped or finished the tour. If so, don't show it to them again.
    const maybeTourVersion = localStorage.getItem(tourCompletionCookieName);
    console.debug('[singularity] Tour cookie:', maybeTourVersion);
    if (maybeTourVersion !== Constants.CURRENT_TOUR_VERSION) {
      console.debug('[singularity] The user hasn\'t done this version of the tour, starting from the beginning.');
      setStepIndex(0);
      setRun(true);
    }

    document.getElementById(Constants.TOUR_BUTTON_ID).onclick = startTour;
  }, []);

  // This restarts the tour from the beginning.
  const startTour = () => {
    console.debug('[singularity] Restarting tour');
    localStorage.setItem(tourCompletionCookieName, 'invalidated');
    setStepIndex(0);
    setRun(true);
    setResetTour(resetTour + 1);
  }

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;

    // If the tour is FINISHED or SKIPPED, set the 'run' state to false so the
    // tour can be started again later.
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      console.debug('[singularity] User finished/skipped tour. Saving a cookie to remember.');
      localStorage.setItem(tourCompletionCookieName, Constants.CURRENT_TOUR_VERSION);
      setStepIndex(0);
      setRun(false);
      // Important! Set the global userShouldDoTour so that another button click will restart.
      dispatch(setUserShouldDoTour(false));
      return;
    }

    // Always allow the user to step backwards.
    if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV) {
      setStepIndex(index - 1);
    } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT) {
      const userDidSelectRegion = mapState.mapSelection.size > 0;

      const userDidSelectFuelType = appState.fuelCategories.size > 0 &&
                                    !appState.fuelCategories.has(FuelCategory.All);

      // If the user didn't take the intended action, just do it for them
      if (index === 1 && !userDidSelectRegion) {
        dispatch(editMapSelection({ editMode: 'overwrite', mapLevel: mapState.mapLevel, mapSelection: new Set(["1"]) }));
      }
      if (index === 2 && !tourState.userDidClickZoom) {
        const zoomButton: HTMLElement = document.querySelector('.tour--zoom-to-selection');
        if (zoomButton) {
          zoomButton.click();
        }
        return;
      }
      if (index === 7 && !userDidSelectFuelType) {
        dispatch(updateAppState({ fuelCategories: new Set<FuelCategory>([FuelCategory.Natural_Gas])}));
      }
      // Wait for the user to exit the current region.
      if (index === 10 && userDidSelectRegion) {
        const exitButton: HTMLElement = document.querySelector('.tour--exit-region');
        if (exitButton) {
          exitButton.click();
        }
        return;
      }
      setStepIndex(index + 1);
    } else if (type === EVENTS.TARGET_NOT_FOUND) {
      console.error('Target for joyride tour not found!');
      setStepIndex(index + 1);
    }
  };

  useEffect(() => {
    if (stepIndex === 0) {
      return;
    }
    handleJoyrideCallback({
      type: EVENTS.STEP_AFTER,
      action: ACTIONS.NEXT,
      controlled: true,
      index: stepIndex,
      size: steps.length,
      lifecycle: LIFECYCLE.COMPLETE,
      status: STATUS.RUNNING,
      step: steps[stepIndex]
    });
  }, [tourState.userDidClickPlant, tourState.userDidClickZoom, tourState.userShouldDoTour, mapState]);

  return (
    <div className="joyride-tour">
      <Joyride
        key={resetTour}
        continuous
        hideBackButton
        hideCloseButton
        run={run}
        callback={handleJoyrideCallback}
        scrollToFirstStep
        disableScrolling
        showProgress
        spotlightClicks
        showSkipButton
        steps={steps}
        stepIndex={stepIndex}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: '#0082ca',
            backgroundColor: '#fff',
          },
        }}
      />
    </div>
  );
}


export default JoyrideTour;
