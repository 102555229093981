import Grid from '@mui/material/Grid';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect } from 'react';
import Select from 'react-select';

import { HelpPopup } from 'components/HelpPopup';
import * as Constants from 'constants/constants';
import {
  Duration,
  FuelCategory,
  FuelMapping,
  MonthOrSeason,
} from 'constants/enums';
import { currentAppState } from 'modules/app/selectors';
import { updateAppState } from 'modules/app/slice';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { FuelTypeColorStyle, formatGroupLabel, singularitySelectStyle } from 'utils/ReactSelectStyle';
import { TrackEventNames, tracker } from 'utils/tracker';

import {
  IPollutantOption,
  ISelectOption,
  expandedFuelTypeOptions,
  fuelTypeOptions,
  monthOrSeasonOptions,
  pollutantOptions,
} from './constants';
import { SingleDatePicker, YearPicker } from './dateOptions';
import IconHelpOptions from './iconOptions';
import './style.css';



export interface IOptionsToolbarProps {
  toggleSettingsMenu?: () => void;
  datePickerType?: 'year' | 'date';
  showTodayButton?: boolean;
  maxDate?: Dayjs;
  leftMostSelection?: 'pollutant' | 'regionSource';
  latestRealtimeRequestFinished?: number;
}



interface IFuelCategoryOption extends ISelectOption {
  value: FuelCategory
}


interface IMonthOrSeasonOption extends ISelectOption {
  value: MonthOrSeason
}


const OptionsToolbar = ({
  toggleSettingsMenu,
  datePickerType,
  showTodayButton=true,
  maxDate=dayjs().endOf('day'),
  leftMostSelection='pollutant',
  latestRealtimeRequestFinished,
}: IOptionsToolbarProps) => {
  const dispatch = useAppDispatch();
  const appState = useAppSelector(currentAppState);
  let fuelOptions = [...fuelTypeOptions];

  useEffect(() => {
    tracker.track(TrackEventNames.CHANGED_POLLUTANT, {pollutant: appState.pollutant});
  }, [appState.pollutant]);

  if (appState.fuelMapping === FuelMapping.Expanded) {
    fuelOptions = fuelOptions.concat(expandedFuelTypeOptions);
  }

  // TODO(milo): Can combine args now.
  const disableMonthOrSeasonSelection = [Duration.Hour, Duration.Day].includes(appState.timeResolution) || datePickerType === 'date';
  const isRealtime = leftMostSelection === 'regionSource';
  const isMarginal = window.location.pathname.endsWith('/marginal');

  const changeSelectedCategories = (value: IFuelCategoryOption[]) => {
    const selectedFuels = new Set<FuelCategory>();
    value.forEach(({ value }) => {
      if (value === FuelCategory.All_Fossil) {
        selectedFuels.add(FuelCategory.Coal);
        selectedFuels.add(FuelCategory.Oil);
        selectedFuels.add(FuelCategory.Natural_Gas);
      } else if (value === FuelCategory.All_Renewable) {
        selectedFuels.add(FuelCategory.Wind);
        selectedFuels.add(FuelCategory.Solar);
        selectedFuels.add(FuelCategory.Hydro);
      } else if (value === FuelCategory.All_Other) {
        selectedFuels.add(FuelCategory.Other);
        selectedFuels.add(FuelCategory.Nuclear);
      } else {
        selectedFuels.add(value);
      }
    });
    dispatch(updateAppState({ fuelCategories: selectedFuels }));
  };

  const changeResolution = ({value}: {value: string}) => {
    dispatch(updateAppState({ dataResolution: (value as '5m' | '1h') }))
  }

  return (
    // NOTE(milo): The map controls have a z-index of 100, so make this appear above.
    <Grid container spacing={2} sx={{zIndex: 11, p: 1, pt: 0, mt: 0, mb: 0}} className="options-toolbar">
      {!isRealtime &&
        <Grid item xs={6} md={2} lg={1} sx={{display: "flex", flexDirection: "column"}}>
          <label className="form-label--toolbar">Pollutant
            <HelpPopup popupContent={
              <>
                For more information on how we calculate emissions for each pollutant, see our technical
                <a target="_blank" href={Constants.DASHBOARD_DOCS_URL}>documentation</a>. For additional information
                on what "CO<sub>2</sub>e" is and how it's calculated, see <a target="_blank" href="https://docs.singularity.energy/docs/open-grid-emissions/ghg_emissions-calculating-ghg-emissions#calculating-co2-equivalent-emissions">this page</a> in the documentation.
              </>}
            />
          </label>
          <Select
            styles={singularitySelectStyle}
            className='react-select-toolbar'
            options={pollutantOptions}
            onChange={(value: IPollutantOption, _) => {
              dispatch(updateAppState({ pollutant: value.value }));
            }}
            defaultValue={pollutantOptions.find(el => (el.value === appState.pollutant))}
          />
        </Grid>
      }
      {!isRealtime &&
        <Grid item xs={12} md={6} lg={3} sx={{display: "flex", flexDirection: "column"}}>
          <label className="form-label--toolbar">Fuel Type(s)
            <HelpPopup popupContent={
              <React.Fragment>
                Only show plants of a certain fuel type or category. The charts on the left will
                only include emissions and electricity data for the selected fuels.
              </React.Fragment>}
            />
          </label>
          <Select
            isMulti
            className='react-select-toolbar tour--fuel-types'
            options={fuelOptions}
            onChange={changeSelectedCategories}
            formatGroupLabel={formatGroupLabel}
            // @ts-expect-error: react-select doesn't seem to like JSX syntax in the values' labels
            value={[...appState.fuelCategories].map(val => fuelOptions.find(d => d.value === val)).filter(x => !!x)}
            styles={FuelTypeColorStyle}
          />
        </Grid>
      }
      {isRealtime &&
        <Grid item xs={6} md={2} lg={2} sx={{display: "flex", flexDirection: "column"}} className='realtime-tour-data-resolution'>
          <label className="form-label--toolbar">Data Resolution
            <HelpPopup popupContent={<React.Fragment>Decide whether to show the data on hourly or 5-minute intervals.</React.Fragment>}
            />
          </label>
          <Select
            className='react-select-toolbar tour--fuel-types'
            options={[{label: 'Hourly', value: '1h'}, {label: '5-minute', value: '5m'}]}
            isDisabled={isMarginal}
            onChange={changeResolution}
            value={appState.dataResolution === '1h' && !isMarginal ? {label: 'Hourly', value: '1h'} : {label: '5-minute', value: '5m'}}
          />
        </Grid>
      }
      {datePickerType === 'date' ? <SingleDatePicker showTodayButton={showTodayButton} maxDate={maxDate} /> : <YearPicker />}
      {!isRealtime &&
        <Grid item xs={6} md={2} lg={1} sx={{display: "flex", flexDirection: "column"}}>
          <label className="form-label--toolbar">Month(s)
            <HelpPopup
              popupContent={
                <React.Fragment>
                  Only plot one month or quarter of data out of the year. For example, you could compare emissions during every January from 2010-2020.
                </React.Fragment>
              }
            />
          </label>
          <Select
            className='react-select-toolbar'
            options={monthOrSeasonOptions}
            onChange={(value: IMonthOrSeasonOption, _) => {
              dispatch(updateAppState({ monthOrSeason: value.value }));
            }}
            defaultValue={monthOrSeasonOptions.find(el => (el.value === appState.monthOrSeason))}
            styles={singularitySelectStyle}
            isDisabled={disableMonthOrSeasonSelection}
          />
        </Grid>
      }
      <IconHelpOptions toggleSettingsMenu={toggleSettingsMenu}/>
      {isRealtime && latestRealtimeRequestFinished !== 0 &&
        <div className="realtime-data-last-updated--label realtime-tour-last-updated">Data last updated: {new Date(latestRealtimeRequestFinished).toLocaleString('default', {month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit', timeZoneName: 'short'})}</div>
      }
    </Grid>
  );
}


export default OptionsToolbar;
