import React from 'react';

import { formatNumber, formatPollutant } from 'utils/utils'
import { EmissionFramework, Pollutant, Units } from 'constants/enums';
import { HelpPopup } from 'components/HelpPopup';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { Dayjs } from 'dayjs';
import { useAppSelector } from 'modules/store';
import { currentAppState } from 'modules/app/selectors';


interface IDataSummaryPanelProps {
  loading: boolean
  emissionFramework: EmissionFramework
  pollutant: Pollutant
  totalMwh: number
  totalEmissions: {
    CO2: number,
    SO2: number,
    NOx: number,
    CO2e: number,
  }
  emissionIntensity: {
    CO2: number,
    SO2: number,
    NOx: number,
    CO2e: number,
  }
  startDate: Dayjs
  endDate: Dayjs
  mapRegions: string[]
}


const DataSummaryPanel = (props: IDataSummaryPanelProps) => {
  const appState = useAppSelector(currentAppState);
  const units = appState.units;

  const emissionFrameworkStr = {
    [EmissionFramework.Generated]: 'Generation',
    [EmissionFramework.Consumed]: 'Consumption'
  }[props.emissionFramework];

  const emissionFrameworkStr2 = {
    [EmissionFramework.Generated]: 'Generated',
    [EmissionFramework.Consumed]: 'Consumed'
  }[props.emissionFramework];

  const regionText = props.mapRegions.map(r => <Chip key={r} label={r}/>)
  const startDateText = <Chip label={props.startDate.format('MMM D, YYYY')}/>
  const endDateText = <Chip label={props.endDate.format('MMM D, YYYY')}/>
  const isImperialUnits = units === Units.Imperial;
  const conversionDivisor = isImperialUnits ? 1 : 2.2;
  const totalEmissions = props.totalEmissions[props.pollutant] / conversionDivisor;
  const emissionsIntensity = props.emissionIntensity[props.pollutant] / conversionDivisor;

  return (
    <>
    { props.mapRegions.length > 0
      ? <div style={{padding: '8px', textAlign: 'center'}}>
        Showing data for {regionText} from {startDateText} to {endDateText}
        </div>
      : ''
    }
    <Grid container>
      <Grid item xs={4} p={2} display='flex' flexDirection='column' alignItems='center'>
        <p className="summary--category">Total {emissionFrameworkStr}</p>
          <Tooltip title="Total generation for the entire time period selected" placement='top-start' arrow>
            <p className="summary--big-number">{formatNumber(props.totalMwh, 2) || '-'}</p>
          </Tooltip>
        <p className="summary--units text-muted">MWh</p>
      </Grid>
      <Grid item xs={4} p={2} display='flex' flexDirection='column' alignItems='center'>
        <p className="summary--category">{emissionFrameworkStr2} Emissions</p>
        <Tooltip title="Total emissions for the entire time period selected" placement='top-start' arrow>
          <p className="summary--big-number">{props.loading ? '-' : formatNumber(totalEmissions / (isImperialUnits ? 2000 : 1000), 2) || '-'}</p>
        </Tooltip>
        <p className="summary--units text-muted">tons {formatPollutant(props.pollutant)}</p>
      </Grid>
      <Grid item xs={4} p={2} display='flex' flexDirection='column' alignItems='center'>
        <p className="summary--category">{emissionFrameworkStr2} Intensity
        <HelpPopup popupContent={
          <React.Fragment>
          <b>Emission intensity</b> is calculated by dividing the total emissions (in {isImperialUnits ? 'lbs' : 'kg'}) by the total electricity from (in MWh).
          </React.Fragment>}
        />
        </p>
        <Tooltip title="Average emission intensity for the entire time period selected" placement='top-start' arrow>
          <p className="summary--big-number">{props.loading ? '-' : formatNumber(emissionsIntensity, 2) || '-'}</p>
        </Tooltip>
        <p className="summary--units text-muted">{isImperialUnits ? 'lbs' : 'kg'} {formatPollutant(props.pollutant)} / MWh</p>
      </Grid>
    </Grid>
    </>
  );
}

export default DataSummaryPanel;
