import { MapLevel, Duration, EmissionFramework } from 'constants/enums';
import { IAppSlice, IMapState } from 'modules/app/slice';


interface IDataAvailability {
  emissionFrameworks: Set<EmissionFramework>
  mapLevels: Set<MapLevel>
  timeResolutions: Set<Duration>
}


export function isAliasForBA(mapLevel: MapLevel) {
  return [MapLevel.BAs, MapLevel.ISOs, MapLevel.LBAs].includes(mapLevel);
}


// This function that decides what data options should be shown to the user.
// It ensures that they can't select a configuration that is invalid and would
// result in no data being returned.
// NOTE: Each option is considered *independently* from the others, and the
// returned set of options are not mutually compatible.
export function getDataAvailability(appState: IAppSlice, mapState: IMapState): IDataAvailability {
  let emissionFrameworks = new Set<EmissionFramework>([EmissionFramework.Generated]);
  let mapLevels = new Set<MapLevel>([MapLevel.LBAs, MapLevel.BAs, MapLevel.ISOs]);
  let timeResolutions = new Set<Duration>();

  // We don't have consumed data at the monthly/yearly level due to limited EIA-930 data.
  // Only allow consumed data to be shown when viewing Hourly eGRID data (hourly/daily).
  // const consumedIsAvailable =
  //     isAliasForBA(mapState.mapLevel) &&
  //     [Duration.Day, Duration.Hour].includes(appState.timeResolution);
  // if (consumedIsAvailable) {
  //   emissionFrameworks.add(EmissionFramework.Consumed);
  // }
  // const consumedIsAvailable = false;

  // If not looking at consumed data, the user can choose States or LRZs.
  if (appState.emissionFramework !== EmissionFramework.Consumed) {
    if (![Duration.Day, Duration.Hour].includes(appState.timeResolution)) {
      mapLevels.add(MapLevel.LRZs);
      mapLevels.add(MapLevel.States);
    }

    timeResolutions.add(Duration.Month);
    timeResolutions.add(Duration.Subyear);
    timeResolutions.add(Duration.Year);
  }

  // If looking at the BA level, we can show hourly and daily data.
  if (isAliasForBA(mapState.mapLevel)) {
    timeResolutions.add(Duration.Hour);
    timeResolutions.add(Duration.Day);
  }

  return {
    emissionFrameworks: emissionFrameworks,
    mapLevels: mapLevels,
    timeResolutions: timeResolutions
  };
}
