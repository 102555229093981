import * as React from 'react';

import Select from 'react-select';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { HelpPopup } from 'components/HelpPopup';
import { singularitySelectStyle } from 'utils/ReactSelectStyle';

import { EmissionAdjustment, FuelMapping, Units } from 'constants/enums';

import * as Constants from 'constants/constants';
import { currentAppState } from 'modules/app/selectors';
import { updateAppState } from 'modules/app/slice';
import { useAppDispatch, useAppSelector } from 'modules/store';

import './style.css';


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


interface IUnitsOption {
  value: Units
  label: string
}


interface IEmissionAdjustmentOption {
  value: EmissionAdjustment
  label: string
}


interface IFuelMappingOption {
  value: FuelMapping
  label: string
}


interface SettingsModalProps {
  open: boolean
  setOpen: (open: boolean) => void
}


const SettingsModal = (props: SettingsModalProps) => {
  const dispatch = useAppDispatch();
  const appState = useAppSelector(currentAppState);

  const unitsOptions: IUnitsOption[] = [
    { value: Units.Imperial, label: 'Imperial (lbs/MWh)' },
    { value: Units.Metric, label: 'Metric (kg/MWh)' },
  ];

  const emissionAdjustmentOptions: IEmissionAdjustmentOption[] = [
    { value: EmissionAdjustment.ForElectricity, label: 'Adjusted for Combined Heat and Power (CHP)' },
    { value: EmissionAdjustment.ForElectricityAdjusted, label: 'Adjusted for CHP and Biomass' }
  ];

  if (!window.location.pathname.includes('/realtime')) {
    emissionAdjustmentOptions.unshift({ value: EmissionAdjustment.Unadjusted, label: 'Unadjusted' });
  }

  const fuelCategoriesOptions: IFuelMappingOption[] = [
    { value: FuelMapping.Simple, label: 'Simple' },
    { value: FuelMapping.Expanded, label: 'Expanded' }
  ]

  const isProjectionsPage = window.location.pathname.endsWith('/projections');

  return (
    <div>
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
    >
      <Box sx={style}>
        <Typography
          style={{fontFamily: 'Helvetica Neue Medium', fontSize: '20px'}}
          id="se--settings-modal-title" variant="h6" component="h2">
          Settings
        </Typography>
        <p style={{fontSize: 12}}>{'Current Version: ' + Constants.DASHBOARD_VERSION}</p>
        <Box mt={2}>
          <label className="form-label--toolbar">
            Fuel Type Categories
            <HelpPopup popupContent={
              <React.Fragment>
                {'The \'Expanded\' categories include the \'Geothermal\', \'Biomass\', \'Wood\', and \'Waste\' fuel types.\
                The \'Simple\' categories combine these less common fuels into \'Other\'.'}
              </React.Fragment>}
            />
          </label>
          <Select
            className='settings-modal-select'
            options={fuelCategoriesOptions}
            onChange={(el: IFuelMappingOption, _) => {
              dispatch(updateAppState({ fuelMapping: el.value }));
            }}
            defaultValue={fuelCategoriesOptions.find(el => (el.value === appState.fuelMapping))}
            styles={singularitySelectStyle}
          />
        </Box>
        <Box mt={2}>
          <label className="form-label--toolbar">Units</label>
          <Select
            className='settings-modal-select'
            options={unitsOptions}
            onChange={(el: IUnitsOption, _) => {
              dispatch(updateAppState({ units: el.value }));
            }}
            defaultValue={unitsOptions.find(el => (el.value === appState.units))}
            styles={singularitySelectStyle}
          />
        </Box>
        <Box mt={2}>
          <label className="form-label--toolbar">Emission Adjustments
          <HelpPopup popupContent={
              <React.Fragment>
                {'\'Adjustments\' are a key methodological parameter for emission accounting. \'Unadjusted\' emissions\
                are the simplest, and include all of the emissions from each power plants without modification.\
                Emissions that are \'Adjusted for Combined Heat and Power (CHP)\' account for the fact that some plants\
                produced both heat and power outputs, and only include the fraction of emissions that are associated\
                with electricity production. Finally, in the \'Adjusted for CHP and Biomass\' method, plants\
                that burn renewable, biogenic fuel sources are assumed to produce zero net emissions. For more information,\
                please check out Singularity\'s '}
                <a target="_blank" href={Constants.DASHBOARD_DOCS_URL}>Open Grid Emissions documentation</a>.
              </React.Fragment>}
            />
          </label>
          <Select
            className='settings-modal-select'
            options={emissionAdjustmentOptions}
            onChange={(el: IEmissionAdjustmentOption, _) => {
              dispatch(updateAppState( { emissionAdjustment: el.value }));
            }}
            defaultValue={emissionAdjustmentOptions.find(el => (el.value === (isProjectionsPage ? EmissionAdjustment.ForElectricityAdjusted : appState.emissionAdjustment)))}
            styles={singularitySelectStyle}
            isDisabled={isProjectionsPage}
          />
        </Box>
        <Button
          variant='outlined'
          className='mt-5'
          onClick={() => { props.setOpen(false) }}
          sx={{mt: 2, fontFamily: 'Helvetica Neue', fontSize: '12px', textTransform: 'none', padding: '10px', lineHeight: 'normal'}}>
          Close
        </Button>
      </Box>
    </Modal>
    </div>
  );
}


export default SettingsModal;
