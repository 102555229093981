import { createSlice, PayloadAction } from "@reduxjs/toolkit";


export interface ITourSlice {
  userDidClickZoom: boolean,
  userDidClickPlant: boolean,
  userShouldDoTour: boolean,
  userShouldDoRealtimeTour: boolean,
}


const initialState: ITourSlice = {
  userDidClickPlant: false,
  userDidClickZoom: false,
  userShouldDoTour: false,
  userShouldDoRealtimeTour: false,
}


const tourSlice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    setUserDidClickPlant: (state, action: PayloadAction<boolean>) => {
      state.userDidClickPlant = action.payload;
    },
    setUserDidClickZoom: (state, action: PayloadAction<boolean>) => {
      state.userDidClickZoom = action.payload;
    },
    setUserShouldDoTour: (state, action: PayloadAction<boolean>) => {
      state.userShouldDoTour = action.payload;
    },
    setUserShouldDoRealtimeTour: (state, action: PayloadAction<boolean>) => {
      state.userShouldDoRealtimeTour = action.payload;
    },
  }
});


export default tourSlice.reducer;


export const {
  setUserDidClickPlant,
  setUserDidClickZoom,
  setUserShouldDoTour,
  setUserShouldDoRealtimeTour,
} = tourSlice.actions;
