import React from 'react';


export const rawModelBuiltIconSvg = `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M22.5 27.5h3v-6h6v-3h-6v-6h-3v6h-6v3h6ZM24 44q-8.05-6.85-12.025-12.725Q8 25.4 8 20.4q0-7.5 4.825-11.95Q17.65 4 24 4q6.35 0 11.175 4.45Q40 12.9 40 20.4q0 5-3.975 10.875T24 44Z"/></svg>`;


const ModelBuiltIcon = (props: { fillColor: string, className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox='4 4 44 44' className={props.className}>
      <path
      fillRule="evenodd"
      fill={props.fillColor}
        d="M22.5 27.5h3v-6h6v-3h-6v-6h-3v6h-6v3h6ZM24 44q-8.05-6.85-12.025-12.725Q8 25.4 8 20.4q0-7.5 4.825-11.95Q17.65 4 24 4q6.35 0 11.175 4.45Q40 12.9 40 20.4q0 5-3.975 10.875T24 44Z"/>
    </svg>
  );
}


export default ModelBuiltIcon;
