import { createTheme } from "@mui/material/styles";


export const Theme = createTheme({
  palette: {
    primary: {
      main: '#0082ca'
    },
    secondary: {
      main: '#ffffff'
    }
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          fontFamily: 'Helvetica Neue',
          lineHeight: 'normal',
          padding: '8px',
          textTransform: 'none',
          backgroundColor: 'white',
          fontWeight: '300',
          color: 'black',
          borderColor: 'lightgray',
          '&:hover': {
            borderColor: '#0082ca',
            color: '#0082ca',
            backgroundColor: 'white'
          }
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          fontFamily: 'Helvetica Neue',
          lineHeight: 'normal',
          padding: '10px',
          textTransform: 'none',
          backgroundColor: 'white',
          borderColor: 'lightgray',
          fontWeight: '300',
          "&.Mui-selected": {
            backgroundColor: "#0082ca",
            color: '#ffffff',
            "&:hover": {
              color: '#ffffff',
              backgroundColor: '#0082ca'
            }
          },
          "&:hover": {
            color: '#0082ca',
            backgroundColor: 'white'
          }
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          // color: 'white',
          // fill: 'white'
          // '&:hover': {
          //   border: '1px solid white'
          // }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#0082ca',
          '&$checked': {
            color: '#0082ca',
          }
        }
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#0082ca',
          color: 'white',
          '&:hover': {
            backgroundColor: '#0082ca',
            color: 'white',
            boxShadow: '0 5px 15px rgba(50, 50, 50, .4)'
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '12px',
          fontFamily: 'Helvetica Neue',
          lineHeight: 'normal',
          padding: '10px',
          textTransform: 'none',
          backgroundColor: 'none',
          fontWeight: '300',
          paddingLeft: '0px',
        },
      },
    }
  },
});
