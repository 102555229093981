import React, { CSSProperties } from 'react';
import { StylesConfig } from 'react-select';

import { fuelCategoryPalette } from 'utils/ColorPalette';


const focusedColor = '#343a40';
const selectedColor = '#0082ca';


export const singularitySelectStyle = {
  option: (provided: any, state: any) => {
    let backgroundColor = 'white';
    if (state.isSelected) {
      backgroundColor = selectedColor;
    } else if (state.isFocused) {
      backgroundColor = focusedColor;
    }
    return {
      ...provided,
      color: (state.isSelected || state.isFocused) ? 'white' : 'black',
      backgroundColor: backgroundColor,
    }
  },
  control: (provided: any, state: any) => ({
    ...provided,
    '&:hover': { borderColor: focusedColor }, // border style on hover
    border: '1px solid lightgray', // default border color
    boxShadow: 'none', // no box-shadow
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition, padding: '2px' };
  }
}


const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};


const groupBadgeStyles: CSSProperties = {
  backgroundColor: 'black',
  borderRadius: '2em',
  color: 'white',
  display: 'inline-block',
  fontSize: 10,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};


interface IFuelTypeOption {
  label: string
  value: string
}


export const FuelTypeColorStyle: StylesConfig<IFuelTypeOption> = {
  option: (provided: any, state: any) => ({
    ...provided,
    // color: fuelCategoryPalette[data.value.toString()],
    fontWeight: 'normal',
    // fontSize: '10px !important',
    color: (state.isSelected || state.isFocused) ? 'white' : 'black',
    backgroundColor: (state.isSelected || state.isFocused) ? fuelCategoryPalette[state.data.value.toString()] : 'white'
  }),
  control: (provided, state) => ({
    ...provided,
    '&:hover': { borderColor: focusedColor }, // border style on hover
    border: '1px solid lightgray', // default border color
    boxShadow: 'none', // no box-shadow
    // fontSize: '10px !important',
  }),
  multiValue: (styles, { data }) => {
    const fillColor = fuelCategoryPalette[data.value.toString()];
    return {
      ...styles,
      backgroundColor: fillColor,
      color: 'white !important',
      // fontSize: '10px !important',
    };
  },
  multiValueLabel: (styles, { data }) => {
    return {
      ...styles,
      color: 'white !important',
      mixBlendMode: 'hard-light',
      // fontSize: '10px !important',
    };
  },
};


export const formatGroupLabel = (data: any) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);
