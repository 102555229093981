import { IUser } from "api/user";
import { HEAP_APP_ID } from "constants/constants";

declare const heap: {load: (appId: string) => void, identify: (id: string) => void, addUserProperties: (props: Record<string, unknown>) => void, track: (event: string, props: Record<string, unknown>) => void};


// Central place to show all events that we track
export enum TrackEventNames {
  DOWNLOAD = 'Clicked download',
  VIEWED_CHART = 'Viewed chart',
  CHANGED_POLLUTANT = 'Changed pollutant',
  REQUESTED_DATA = 'Requested data',
}

class Tracker {
  constructor() {
    heap.load(HEAP_APP_ID);
  }

  identify(user: IUser) {
    try {
      heap.identify(user.email);
      heap.addUserProperties({
        name: user.name,
        customer_id: user.customer_id,
        email: user.email,
        role: user.role,
        user_id: user.id,
        status: user.status,
      });
    } catch (err) {
      console.warn('Failed to identify user', err);
    }
  }

  track(event: TrackEventNames, properties: Record<string, unknown> = {}) {
    try {
      heap.track(event, properties);
    } catch (error) {
      console.warn('failed to track event', event, error);
    }
  }
}

export const tracker = new Tracker();