import React from 'react';


const CircleIcon = (props: { fillColor: string, className?: string }) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={props.className}>
      <circle cx="12" cy="12" r="12" fill={props.fillColor}/>
    </svg>
  );
}


export default CircleIcon;
