import * as Constants from 'constants/constants';
import dayjs from 'dayjs';

import { Duration, MapLevel, MonthOrSeason, Pollutant } from "constants/enums";
import { IAppSlice, IMapState } from "modules/app/slice";

import { CompactEmissionEvent, IScalarByPollutant } from "constants/interfaces";
import { TrackEventNames, tracker } from 'utils/tracker';
import { monthOrSeasonToString, replaceAll } from 'utils/utils';


export const queryParamsAreComplete = (appState: IAppSlice, mapState: IMapState) =>  {
  // The user might be zoomed into MISO with no subregions selected, but implicitly with MISO selected.
  const didSelectRegions = mapState.mapSelection.size > 0 ||
                           mapState.mapLevel === MapLevel.LRZs;
  const didSelectDates = appState.queryStartDate != null && appState.queryEndDate != null;
  const didSelectPollutant = appState.pollutant;
  return didSelectRegions && didSelectDates &&
         didSelectPollutant;
}


export const processApiData = (
  seriesByFuelCategory: {[f: string]: CompactEmissionEvent[]},
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs,
  pollutant: Pollutant
) => {
  if (!seriesByFuelCategory) {
    return null;
  }
  // If startDate or endDate have changed, filter out data here.
  const fuelCategories = Object.getOwnPropertyNames(seriesByFuelCategory);

  let totalsByFuelCategory: {
    generation: {[fuelCategory: string] : number}
    emissions: {[fuelCategory: string] : IScalarByPollutant}
    intensity: {[fuelCategory: string] : IScalarByPollutant}
  } = {
    generation: {},
    emissions: {},
    intensity: {}
  }
  let totalGeneration = 0;
  let totalEmissions = { CO2: 0, NOx: 0, SO2: 0, CO2e: 0 };

  // Get data for the fuel mix chart.
  fuelCategories.forEach((fuelCategory: string) => {
    let generationThisFuelCategory = 0;
    let emissionsThisFuelCategory = { CO2: 0, NOx: 0, SO2: 0, CO2e: 0 }

    seriesByFuelCategory[fuelCategory].filter((event: CompactEmissionEvent) => {
      const eventStart = dayjs(event.startDate);
      return (eventStart >= startDate && eventStart <= endDate);
    }).forEach((event: CompactEmissionEvent) => {
      generationThisFuelCategory += event.netElectricityMwh;
      emissionsThisFuelCategory.CO2 += event.co2MassLb;
      emissionsThisFuelCategory.NOx += event.noxMassLb;
      emissionsThisFuelCategory.SO2 += event.so2MassLb;
      emissionsThisFuelCategory.CO2e += event.co2EMassLb;
    });

    const intensityThisFuelCategory = {
      CO2: emissionsThisFuelCategory.CO2 / generationThisFuelCategory,
      NOx: emissionsThisFuelCategory.NOx / generationThisFuelCategory,
      SO2: emissionsThisFuelCategory.SO2 / generationThisFuelCategory,
      CO2e: emissionsThisFuelCategory.CO2e / generationThisFuelCategory
    };

    totalsByFuelCategory.generation[fuelCategory] = generationThisFuelCategory;
    totalsByFuelCategory.emissions[fuelCategory] = emissionsThisFuelCategory;
    totalsByFuelCategory.intensity[fuelCategory] = intensityThisFuelCategory;

    // Add contribution of this fuel to the total generation and emissions.
    totalGeneration += generationThisFuelCategory;
    totalEmissions.CO2 += emissionsThisFuelCategory.CO2;
    totalEmissions.SO2 += emissionsThisFuelCategory.SO2;
    totalEmissions.NOx += emissionsThisFuelCategory.NOx;
    totalEmissions.CO2e += emissionsThisFuelCategory.CO2e;
  });

  // Finally, compute the average intensity.
  const totalIntensity = {
    CO2: totalEmissions.CO2 / totalGeneration,
    NOx: totalEmissions.NOx / totalGeneration,
    SO2: totalEmissions.SO2 / totalGeneration,
    CO2e: totalEmissions.CO2e / totalGeneration
  };

  const fuelMix = Object.entries(totalsByFuelCategory.generation).map(([fuelCategory, value]) => {
    return {
      fuelCategory: fuelCategory,
      value: value
    };
  });

  const emissionMix = Object.entries(totalsByFuelCategory.emissions).map(([fuelCategory, value]) => {
    return {
      fuelCategory: fuelCategory,
      value: value[pollutant]
    };
  });

  return {
    fuelCategories: fuelCategories,
    totalsByFuelCategory: totalsByFuelCategory,
    totalGeneration: totalGeneration,
    totalEmissions: totalEmissions,
    averageIntensity: totalIntensity,
    fuelMix: fuelMix,
    emissionMix: emissionMix
  };
}


export const getTickPositions = (appState: IAppSlice, plotData: {x: number, y: number}[]) => {
  // NOTE(milo): This is my really ugly way of formatting the chart x axis.
  // Highchart's default tick formatting didn't work with the "filter by month"
  // functionality, so we implement a custom axis format here.
  // https://stackoverflow.com/questions/7101464/how-to-get-highcharts-dates-in-the-x-axis
  let tickPositionsSet = new Set();

  plotData.forEach((el: any) => {
    el.data.map((xy: any) => tickPositionsSet.add(xy.x));
  });
  // @ts-ignore
  let tickPositionsArray: number[] = [...tickPositionsSet].sort();
  let tickLabelsFormat = '{value:%Y-%m-%d}';

  if (appState.timeResolution === Duration.Year) {
    if (appState.monthOrSeason === MonthOrSeason.All) {
      tickLabelsFormat = '{value:%Y}';
    } else {
      tickLabelsFormat = `{value:${monthOrSeasonToString(appState.monthOrSeason)} %Y}`;
    }
  } else if (appState.timeResolution === Duration.Month) {
    tickLabelsFormat = '{value:%b %Y}';
    // If more than X months, only show Jan.
    if (tickPositionsArray.length > 10*12) {
      tickPositionsArray = tickPositionsArray.filter((ts: number) => {
        const dt = dayjs.utc(ts);
        return (dt.month() === 0);
      });
    // If less than X months, but more than Y, show Jan and Jul.
    } else if (tickPositionsArray.length > 5*12) {
      tickPositionsArray = tickPositionsArray.filter((ts: number) => {
        const dt = dayjs.utc(ts);
        return (dt.month() === 0 || dt.month() === 6);
      });
    // If less than Y months, but more than Z, show Jan/Apr/Jul/Oct.
    } else if (tickPositionsArray.length > 2*12) {
      tickPositionsArray = tickPositionsArray.filter((ts: number) => {
        const dt = dayjs.utc(ts);
        return (dt.month() === 0 || dt.month() === 3 || dt.month() === 6 || dt.month() === 9);
      });
    }
  } else if (appState.timeResolution === Duration.Subyear) {
    tickLabelsFormat = '{value:%b %Y}';
    if (tickPositionsArray.length >= 4*15) {
      tickPositionsArray = tickPositionsArray.filter((ts: number) => {
        const dt = dayjs.utc(ts);
        return (dt.month() === 0);
      });
    }
  } else if (appState.timeResolution === Duration.Day) {
    // NOTE(milo): May want to include year if we show more than 1 year of data?
    tickLabelsFormat = '{value:%b %d}';
    // If showing more than 3m, showing the 1st and 15th day of the month.
    if (tickPositionsArray.length >= 6*30) {
      tickPositionsArray = tickPositionsArray.filter((ts: number) => {
        const dt = dayjs.utc(ts);
        return (dt.date() === 1);
      });
    } else if (tickPositionsArray.length >= 60) {
      tickPositionsArray = tickPositionsArray.filter((ts: number) => {
        const dt = dayjs.utc(ts);
        return (dt.date() === 1 || dt.date() === 15);
      });
    } else {
      tickPositionsArray = tickPositionsArray.filter((ts: number) => {
        const dt = dayjs.utc(ts);
        return (dt.date() === 1 || dt.date() === 8 || dt.date() === 15 || dt.date() === 22);
      });
    }
  } else if (appState.timeResolution === Duration.Hour) {
    tickLabelsFormat = '{value: %l%P, %b %d}'
    if (tickPositionsArray.length >= 24*14) {
      tickPositionsArray = tickPositionsArray.filter((ts: number) => {
        const dt = dayjs.utc(ts);
        return (dt.hour() === 0);
      });
    } else if (tickPositionsArray.length >= 24*7) {
      tickPositionsArray = tickPositionsArray.filter((ts: number) => {
        const dt = dayjs.utc(ts);
        return (dt.hour() === 0 || dt.hour() === 12);
      });
    } else {
      tickPositionsArray = tickPositionsArray.filter((ts: number) => {
        const dt = dayjs.utc(ts);
        return (dt.hour() === 0 || dt.hour() === 6 || dt.hour() === 12 || dt.hour() === 18);
      });
    }
  }

  return {
    positions: tickPositionsArray,
    format: tickLabelsFormat
  };
}

export const createDownloadFunction = (appState: IAppSlice, mapState: IMapState, rows: (string | number)[][]) => {
  // https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
  return (event: MouseEvent) => {
    console.log('[singularity] Creating a file download');
    const downloadTarget = document.getElementById(Constants.DOWNLOAD_LINK_ID);

    if (rows.length === 0) {
      alert('No data to export!')
      console.warn('[singularity] No data to create a download file with.');
      return;
    }

    // e.g MISO+PJM+CAISO or MISO_LRZ_0+1+2.
    let regionName = Array.from(mapState.mapSelection).join('+');
    if (mapState.mapLevel === MapLevel.LRZs) {
      regionName = 'MISO_LRZ_' + regionName;
    }
    const d0 = appState.queryStartDate.year().toString();
    const d1 = appState.queryEndDate.year().toString();
    const tr = appState.timeResolution.toLowerCase();
    const method = appState.emissionFramework.toString().toLowerCase();
    const poll = appState.pollutant.toString().toLowerCase();
    const adj = replaceAll(appState.emissionAdjustment.toString().toLowerCase(), ' ', '_');
    const seriesFile = `${method}_emissions_${regionName}_${d0}_${d1}_${poll}_${adj}_${tr}.csv`;
    console.debug(`Creating download '${seriesFile}'`);

    const csvContent = 'data:text/csv;charset=utf-8,' +
        rows.map(e => e.join(',')).join('\n');
    const csvEncodedUri = encodeURI(csvContent);

    downloadTarget.setAttribute('href', csvEncodedUri);
    downloadTarget.setAttribute('download', seriesFile);
    downloadTarget.click();

    downloadTarget.setAttribute('href', Constants.DATA_DOWNLOAD_README_URL);
    downloadTarget.setAttribute('download', 'README.md')
    downloadTarget.click();

    tracker.track(TrackEventNames.DOWNLOAD, {})
  }
}