import React from 'react';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { FuelMapping } from 'constants/enums';
import { currentAppState } from "modules/app/selectors";
import { useAppSelector } from "modules/store";
import { fuelCategoryPalette } from 'utils/ColorPalette';
import { replaceAll } from 'utils/utils';

import CircleIcon from 'components/CircleIcon';

import './style.css';


const FuelCategoryEntry = (props: { color: string, name: string }) => {
  return (
    <Stack direction="row" justifyContent="left" alignItems="center">
      <CircleIcon fillColor={props.color} className="legend-circle-icon"/>
      <p className='legend-entry-text'>
        {replaceAll(props.name, '_', ' ')}
      </p>
    </Stack>
  );
}


const MapLegend = () => {
  const appState = useAppSelector(currentAppState);

  let fuelCategories = [
    'coal', 'natural_gas', 'petroleum', 'solar', 'wind', 'hydro', 'storage', 'nuclear', 'other'
  ];
  if (appState.fuelMapping === FuelMapping.Expanded) {
    fuelCategories = fuelCategories.concat([
      'geothermal', 'biomass', 'waste',
    ]);
  }

  return (
    <Paper className='map-legend-container'>
      {
        fuelCategories.map((name: string) => {
          return <FuelCategoryEntry key={name} name={name} color={fuelCategoryPalette[name]}/>
        })
      }
      <hr/>
      <p className='legend-entry-text' style={{fontSize: '10px'}}>Plant size represents capacity (MW).</p>
    </Paper>
  );
}


export default MapLegend;
