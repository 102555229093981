import React, { useState } from 'react';
import dayjs from 'dayjs';
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { formatNumber } from 'utils/utils';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


interface IMapPlantPopupProps {
  features: MapboxGeoJSONFeature[]
}


const MapPlantPopup = (props: IMapPlantPopupProps) => {
  const [popupIndex, setPopupIndex] = useState(0);

  const nextPopup = () => {
    const nextIndex = popupIndex + 1;
    setPopupIndex(nextIndex >= props.features.length ? 0 : nextIndex);
  }

  const prevPopup = () => {
    const prevIndex = popupIndex - 1;
    setPopupIndex(prevIndex < 0 ? props.features.length - 1 : prevIndex);
  }

  if (props.features.length === 0) {
    return <p>No features available to show.</p>;
  }

  const feature = props.features[popupIndex];

  const plantIdEia = feature.properties.plantIdEia;
  const plantName = feature.properties.name;
  const balancingAuth = feature.properties.baRegion;
  const plantType = feature.properties.plantType;
  const nameplateCapacity = feature.properties.capacityMw;

  const nameplateCapacityHtml = nameplateCapacity > 0 ?
    <tr>
      <td className="table--td">Nameplate Cap.</td>
      <td className="table--td">{formatNumber(nameplateCapacity, 1)} MW</td>
    </tr> : undefined;

  const primaryFuel = feature.properties.primaryFuelCategory;
  let operationStart = feature.properties.operationStart;

  const operationStartHtml = operationStart ?
    <tr>
      <td className="table--td">Operation Start</td>
      <td className="table--td">{dayjs(operationStart).format('MMM YYYY')}</td>
    </tr> : undefined;

  let retirementDate = feature.properties.operationEnd;
  const retirementHtml = retirementDate ?
      <tr>
        <td className="table--td">Retirement Date</td>
        <td className="table--td">{dayjs(retirementDate).format('MMM YYYY')}</td>
      </tr> : undefined;

  const plantIdHtml = plantIdEia  < 900000 ?
    <tr>
      <td className="table--td">EIA Plant ID</td>
      <td className="table--td">{plantIdEia}</td>
    </tr> : undefined;

  return (
    <div>
      <Stack direction="row" justifyContent='center' alignItems='center'>
        <IconButton onClick={prevPopup} size="small"><KeyboardArrowLeftIcon fontSize={'small'}/></IconButton>
        Plant {popupIndex + 1} of {props.features.length}
        <IconButton onClick={nextPopup} size="small"><KeyboardArrowRightIcon fontSize={'small'}/></IconButton>
      </Stack>
      <b>{plantName}</b>
      <table className="table--popup table table-sm table-borderless">
        <tbody>
          <tr>
            <td className="table--td">Plant Type</td>
            <td className="table--td">{plantType}</td>
          </tr>
          <tr>
            <td className="table--td">Balancing Auth.</td>
            <td className="table--td">{balancingAuth}</td>
          </tr>
          {nameplateCapacityHtml}
          <tr>
            <td className="table--td">Primary Fuel</td>
            <td className="table--td">{primaryFuel}</td>
          </tr>
          {operationStartHtml}
          {retirementHtml}
          {plantIdHtml}
        </tbody>
      </table>
    </div>
  )
}


export default MapPlantPopup;
