import React from 'react';
import ReactDOM from 'react-dom';

import { store } from 'modules/store';
import { Provider } from 'react-redux';

import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Theme } from './Theme';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from './App';

import * as Constants from 'constants/constants';
import './css/index.css';


// if (Constants.DEBUG) {
//   window.store = store;
// }


if (!Constants.DEBUG) {
  Sentry.init({
    dsn: "https://7096a130bb3346e792e1d125d8928670@o256130.ingest.sentry.io/6653370",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05,
  });
}


ReactDOM.render(
  <Provider store={store}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={Theme}>
        <App/>
      </ThemeProvider>
    </LocalizationProvider>
  </Provider>,
  document.getElementById('root')
);
