import React from "react";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import './style.css'


const ErrorPage = () => {
  return (
    <Box className='error-page-container'>
      <Alert severity="error">
        <AlertTitle>Unknown Error</AlertTitle>
        Something went wrong — our engineering team has been notified.
      </Alert>
    </Box>
  )
}


export default ErrorPage;
